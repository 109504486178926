/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

$primary: #D4AD68;
$secondary: rgba(0, 0, 60, 0.95);

$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;


$themecolor: #1e88e5;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;

//PALETTE
$mat-primary: (
  50: #faf5ed,
  100: #f2e6d2,
  200: #ead6b4,
  300: #e1c695,
  400: #dab97f,
  500: #d4ad68,
  600: #cfa660,
  700: #c99c55,
  800: #c3934b,
  900: #b9833a,
  A100: #ffffff,
  A200: #ffecd3,
  A400: #ffd5a0,
  A700: #ffca86,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
$mat-secondary: (
  50: #e0e0e8,
  100: #b3b3c5,
  200: #80809e,
  300: #4d4d77,
  400: #262659,
  500: #00003c,
  600: #000036,
  700: #00002e,
  800: #000027,
  900: #00001a,
  A10: #5858ff,
  A20: #2525ff,
  A40: #0000f1,
  A70: #0000d8,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
